<template>
    <span class="icon-wrapper">
        <img v-if="imgSrc" :src="imgSrc" class="icon" />
    </span>
</template>

<script>
import scoringIcon from "@/assets/svgs/scoring-icon.svg";
import passingIcon from "@/assets/svgs/passing-icon.svg";
import defendingIcon from "@/assets/svgs/defending-icon.svg";
import receivingIcon from "@/assets/svgs/receiving-icon.svg";

import categoryIcon from "@/assets/svgs/category-icon.svg";

import basicIcon from "@/assets/svgs/level-basic.svg";
import advancedIcon from "@/assets/svgs/level-advanced.svg";
import proIcon from "@/assets/svgs/level-pro.svg";

export default {
    name: "breadcrumbs",
    props: ["type", 'catValue'],
    data() {
        return {
            imgSrc: null,
        };
    },
    mounted() {
        if (this.type && this.type == 'skill') {
            this.returnCatImg(this.catValue.toLowerCase());
        } else if (this.type && this.type == 'level') {
            this.returnLevelImage(this.catValue.toLowerCase());
        }
    },
    methods: {
        returnCatImg(val) {
            if (val && val !== '') {
                if (val == 'passing') {
                    this.imgSrc = passingIcon;
                } else if (val == 'defending') {
                    this.imgSrc = defendingIcon;
                } else if (val == 'scoring') {
                    this.imgSrc = scoringIcon;
                } else if (val == 'receiving') {
                    this.imgSrc = receivingIcon;
                } else {
                    this.imgSrc = categoryIcon;
                }
            } else {
                this.imgSrc = categoryIcon;
            }

        },
        returnLevelImage(val) {
            if (val && val !== '') {
                if (val == 'basic') {
                    this.imgSrc = basicIcon;
                } else if (val == 'advanced') {
                    this.imgSrc = advancedIcon;
                } else if (val == 'pro') {
                    this.imgSrc = proIcon;
                } else {
                    this.imgSrc = proIcon;
                }
            } else {
                this.imgSrc = proIcon;
            }

        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
