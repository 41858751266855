<template>
    <div class="training-nav-wrapper">
        <div class="container">
            <ul>
                <li class="back"><router-link class="back-button" :to="'/my-journeys'">Go back</router-link></li>
                <li class="edit-btn">
                    <button class="button alt" @click="toggleTrainingNavOptions"
                        :class="{ 'active': showTrainingNavOptions }">Edit journey</button>
                    <ul>
                        <li><button class="add-vid" @click="toggleAddToPopup">Add video</button></li>
                        <li><button class="edit-name" @click="toggleEditJourneyNamePopup">Edit name</button></li>
                        <li><button class="delete-journey" @click="showRemoveJourney">Delete journey</button></li>
                    </ul>
                </li>

                <li><button class="button" @click="submitCompleteJourney">Save journey</button></li>
            </ul>
        </div>
    </div>

    <section v-if="trainingContents" class="hero page no-bg type-undefined type-center">
        <div class="container">
            <div class="column-wrapper">
                <div class="column content">
                    <h1 class="block-title">{{ trainingContents.title }}</h1>
                    <!-- <span class="block-subtitle">Find your class</span> -->
                </div>
            </div>
        </div>
    </section>

    <transition name="fade">
        <Popup :elemVisible="addTrainingPopupVisible" :elemClass="'add-item-to-training-popup'"
            @hidePopupEl="hidePopups()">
            <div class="column">
                <TrainingGridCollection :paging="trainingParams"
                    :collectionFilter="['Main Categories', 'Skills', 'Level']" />
            </div>
            <button class="close-btn" @click="hidePopups"></button>
        </Popup>
    </transition>

    <section class="section add-video-section" v-if="trainingContents?.trainingStages && trainingContents.trainingStages[0].trainingStageElements.length == 0
                    ">
        <div class="container">
            <div class="column-wrapper">
                <div class="column content">
                    <div class="big-btn-wrapper">
                        <button class="button" @click="toggleAddToPopup">Add new video</button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section video-overview" v-else-if="trainingContents?.trainingStages &&
                    trainingContents.trainingStages.length > 0
                    ">
        <div class="container">
            <div class="column-wrapper">
                <div class="column content">
                    <ul class="video-training-wrapper">
                        <template v-for="item in trainingContents.trainingStages" :key="item.id">
                            <template v-if="item?.trainingStageElements &&
                    item.trainingStageElements.length > 0
                    ">
                                <li class="training-overview" v-for="trainingEl in item.trainingStageElements"
                                    :key="trainingEl.id">

                                    <template v-if="trainingEl?.videoContent">
                                        <TrainingVideoItem :data="trainingEl.videoContent"
                                            @sortUp="sortOrderUp(trainingEl)" @sortDown="sortOrderDown(trainingEl)"
                                            @deleteItem="deleteStageElement(trainingEl)" />
                                    </template>
                                </li>
                            </template>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <transition name="fade">
        <Popup :elemVisible="removeEntireJourneyPopup" :elemClass="'remove-item-popup'" @hidePopupEl="hidePopups()">
            <div class="column content-column remove-item-column">
                <h2 class="column-title">Are you sure?</h2>
                <ul class="confirmation-options">
                    <li><button class="button" type="button" @click="confirmItemDelete">Yes</button>
                    </li>
                    <li><button class="button alt" type="button" @click="hidePopups">No</button>
                    </li>
                </ul>
            </div>
        </Popup>
    </transition>

    <transition name="fade">
        <Popup :elemVisible="showEditJourneyNamePopup" :elemClass="'edit-journey-form'" @hidePopupEl="hidePopups()">
            <div class="column form-column edit-journey-form">
                <h2 class="column-title">Edit journey name</h2>

                <form @submit.prevent="saveEditJourneyForm">
                    <div v-if="editJourneyShowError" class="error-msg">
                        <p>{{ editJourneyErrorMsg }}</p>
                    </div>
                    <div v-if="editJourneyShowSucces" class="succes-msg">
                        <p>{{ editJourneySuccesMsg }}</p>
                    </div>

                    <div class="form-row">
                        <span class="field-wrapper text">
                            <label class="top-level" for="training-name-input">Journey name</label>
                            <div class="input-wrapper">
                                <input type="text" id="training-name-input" name="trainingName" placeholder="naam"
                                    @keyup="editJourneyChangeInput()" v-model="editJourneyNameVal" />
                            </div>
                        </span>
                    </div>


                    <button class="button submit">
                        Save journey
                    </button>
                    <button class="close-btn" @click="hidePopups"></button>
                </form>
            </div>
        </Popup>
    </transition>
</template>

<script>
import {
    //createTraining,
    fetchTrainingSingle,
    updateTrainingSingle,
    deleteTrainingSingle,
    //fetchTrainingList
} from "@streampac.io/chef_sp_1";

import TrainingGridCollection from "@/components/TrainingGridCollection";

import Popup from "@/components/Popup";

import TrainingVideoItem from "@/components/TrainingVideoItem";

export default {
    name: "home",
    inject: ["isAuthenticated"],
    metaInfo() {
        return {
            title: "LEAD Hockey online",
        };
    },
    data() {
        return {
            showTrainingNavOptions: false,
            removeEntireJourneyPopup: false,
            showEditJourneyNamePopup: false,
            pageData: null,
            searchParams: {},
            showAddJourneyForm: false,
            trainingName: "",
            userId: this.$store.state?.getUserProfile?.userId,
            trainingContents: [],
            addTrainingStageElements: [],
            addTrainingPopupVisible: false,
            fetchDataParams: {
                filter: {
                    searchTerm: "",
                },
                paging: {
                    pageNumber: 1,
                    pageSize: 100,
                },
                orderBy: {
                    orderByField: "sortOrder",
                    orderDesc: false,
                },
                //flattenFields: true,
            },
            trainingParams: {
                pageNumber: 1,
                pageSize: 10,
            },
            showError: false,
            errorMsg: "",
            showSucces: false,
            succesMsg: "",

            editJourneyShowError: false,
            editJourneyErrorMsg: "",
            editJourneyShowSucces: false,
            editJourneySuccesMsg: "",
            editJourneyNameVal: '',
            //collectionFilter: ["Level", "Nr of Video's", "Coaches & Trainers"],
        };
    },
    // components: {
    // 	JourneyFilterCollection,
    // },
    components: {
        TrainingGridCollection,
        Popup,
        TrainingVideoItem
    },
    created() {
        this.getCompleteTraining();
    },
    watch: {
        "$store.state.getUserProfile"() {
            if (this.$store.state?.getUserProfile) {
                this.userId = this.$store.state?.getUserProfile?.userId;

                // this.getTrainingList();
                this.getCompleteTraining();
            }
        },
        // "$store.state.getTrainingStageElements": {
        //     handler() {
        //         if (this.$store.state?.getTrainingStageElements) {
        //             // console.log(this.$store.state?.getTrainingStageElements);
        //         }
        //     },
        //     deep: true,
        // },
        "$store.state.submitTrainingStageElementsToTraining": {
            handler() {
                if (this.$store.state?.submitTrainingStageElementsToTraining) {
                    this.submitTrainingStageElements();

                    // setTimeout(() => {
                    //     //refresh to get the latest info from API again
                    //     this.getCompleteTraining();
                    // }, 100);

                    setTimeout(() => {
                        this.hidePopups();
                    }, 200);
                }
            },
            deep: true,
        },
    },
    methods: {
        getCompleteTraining() {

            this.hidePopups();

            if (this.userId) {

                fetchTrainingSingle(
                    this.isAuthenticated,
                    this.$route.params.slug,
                    '?isFlattenFieldsVisible=false',
                    true
                ).then((response) => {
                    if (response && response.status === 200) {
                        this.trainingContents = response.data;

                        //console.log(response.data);

                        if (
                            this.trainingContents?.trainingStages &&
                            this.trainingContents.trainingStages.length > 0
                        ) {
                            this.trainingContents.trainingStages.sort(
                                (a, b) => a.sortOrder - b.sortOrder
                            );

                            this.trainingContents.trainingStages[0].trainingStageElements.sort(
                                (a, b) => a.sortOrder - b.sortOrder
                            );
                        }

                        if (this.trainingContents?.title && this.trainingContents.title !== '') {
                            this.editJourneyNameVal = this.trainingContents.title;
                        }

                        //console.log(response.data);
                    }
                });
            }
        },
        hidePopups() {
            this.addTrainingPopupVisible = false;
            this.removeEntireJourneyPopup = false;
            this.showTrainingNavOptions = false;
            this.showEditJourneyNamePopup = false;
        },
        submitTrainingStageElements() {
            let params = this.trainingContents;

            if (params.trainingStages.length > 0) {
                if (
                    params.additionalFields == null ||
                    params.additionalFields == "null"
                ) {
                    params.additionalFields = [];
                }

                const originalObj =
                    params.trainingStages[0].trainingStageElements;

                if (originalObj && originalObj.length > 0) {
                    params.trainingStages[0].trainingStageElements = [];

                    originalObj.forEach((element) => {

                        const elemObj = {
                            sortOrder: element.sortOrder,
                            videoContentId: element?.videoContent.id,
                        };

                        params.trainingStages[0].trainingStageElements.push(
                            elemObj
                        );
                    });
                }

                if (
                    this.$store.state?.getTrainingStageElements &&
                    this.$store.state.getTrainingStageElements.length > 0
                ) {
                    let initialSortOrder =
                        params.trainingStages[0].trainingStageElements.length;

                    this.$store.state?.getTrainingStageElements.forEach(
                        (element) => {
                            initialSortOrder++;

                            const elemObj = {
                                sortOrder: initialSortOrder,
                                videoContentId: element,
                            };

                            params.trainingStages[0].trainingStageElements.push(
                                elemObj
                            );
                        }
                    );
                }

                //remove all items
                //params.trainingStages[0].trainingStageElements = [];
                updateTrainingSingle(
                    this.isAuthenticated,
                    this.$route.params.slug,
                    params,
                    true
                ).then((response) => {
                    //console.log(response);
                    if (response && response.status === 200) {
                        this.trainingContents = response.data;

                        if (
                            this.trainingContents?.trainingStages &&
                            this.trainingContents.trainingStages.length > 0
                        ) {
                            this.trainingContents.trainingStages.sort(
                                (a, b) => a.sortOrder - b.sortOrder
                            );

                            this.trainingContents.trainingStages[0].trainingStageElements.sort(
                                (a, b) => a.sortOrder - b.sortOrder
                            );
                        }

                        //reset stuff
                        this.addTrainingStageElements = [];
                        this.$store.commit("setTrainingStageElements", []);

                        //get training info
                        this.getCompleteTraining();
                    }
                });
            }
        },
        toggleAddToPopup() {
            //this.submitCompleteJourney();
            this.addTrainingPopupVisible = !this.addTrainingPopupVisible;

        },
        updateSortOrder(items, idToUpdate, newSortOrder) {
            // Find the index of the item to update
            const itemToUpdateIndex = items.findIndex(item => item.id === idToUpdate);
            const itemToUpdate = items[itemToUpdateIndex];

            if (!itemToUpdate) {
                console.error('Item not found');
                return;
            }

            // Store the original sort order of the item to update
            const originalSortOrder = itemToUpdate.sortOrder;

            // Update the sortOrder of the specified item
            if (newSortOrder <= items.length && newSortOrder > 0) {
                itemToUpdate.sortOrder = newSortOrder;
            }

            // Adjust sortOrders of other items
            items.forEach((item) => {
                if (item.id !== itemToUpdate.id) {
                    if (newSortOrder < originalSortOrder) {
                        // Moving item upwards in the order
                        if (item.sortOrder >= newSortOrder && item.sortOrder < originalSortOrder) {
                            item.sortOrder += 1;
                        }
                    } else if (newSortOrder > originalSortOrder) {
                        // Moving item downwards in the order
                        if (item.sortOrder <= newSortOrder && item.sortOrder > originalSortOrder) {
                            item.sortOrder -= 1;
                        }
                    }
                }
            });

            // Sort the array by the updated sortOrder
            items.sort((a, b) => a.sortOrder - b.sortOrder);

            this.trainingContents.trainingStages[0].trainingStageElements = items;
        },
        sortOrderUp(item) {
            if (item?.sortOrder && item.sortOrder > 0) {
                this.updateSortOrder(this.trainingContents.trainingStages[0].trainingStageElements, item.id, item.sortOrder - 1);
            }

        },
        sortOrderDown(item) {
            if (item.sortOrder < (this.trainingContents.trainingStages[0].trainingStageElements.length + 1)) {
                this.updateSortOrder(this.trainingContents.trainingStages[0].trainingStageElements, item.id, item.sortOrder + 1);
            }
        },
        deleteStageElement(deleteItemObj) {
            let items = this.trainingContents.trainingStages[0].trainingStageElements;

            const itemToDeleteIndex = items.findIndex(item => item.id === deleteItemObj.id);
            //const ItemToDelete = items[itemToDeleteIndex];

            items.splice(itemToDeleteIndex, 1);

            items.forEach((item, index) => {
                item.sortOrder = index + 1;
            });

            this.trainingContents.trainingStages[0].trainingStageElements = items;
        },
        submitCompleteJourney() {
            this.submitTrainingStageElements();

            // setTimeout(() => {
            //     //refresh to get the latest info from API again
            //     this.getCompleteTraining();
            // }, 100);
        },
        showRemoveJourney() {
            this.removeEntireJourneyPopup = !this.removeEntireJourneyPopup;
        },
        confirmItemDelete() {
            deleteTrainingSingle(
                this.isAuthenticated,
                this.$route.params.slug,
                true
            ).then((response) => {
                if (response && response.status === 204) {
                    this.$router.push('/my-journeys/');
                }
            });
        },
        toggleTrainingNavOptions() {
            this.showTrainingNavOptions = !this.showTrainingNavOptions;
        },
        toggleEditJourneyNamePopup() {
            this.showEditJourneyNamePopup = !this.showEditJourneyNamePopup;
        },
        editJourneyChangeInput() {
            this.editJourneyShowError = false;
            this.editJourneyErrorMsg = "";
            this.editJourneyShowSucces = false;
            this.editJourneySuccesMsg = "";
        },
        saveEditJourneyForm() {
            //console.log(this.trainingContents);

            if (this.editJourneyNameVal && this.editJourneyNameVal !== '') {
                this.trainingContents.title = this.editJourneyNameVal;
            }

            this.submitTrainingStageElements();
        }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
