<template>
    <button :class="[
        itemClass,
        {
            isloading: isLoading,
            active: activeClass,
        },
    ]" @click="toggleActiveState">
        <div class="inner-video-item" v-if="data && mainVideoType !== 'Masterclass'">
            <div class="thumbnail">
                <div v-if="data.overviewImageModel &&
        data.overviewImageModel.url &&
        data.overviewImageModel.url !== ''
        " class="background-wrapper" v-bind:style="{
        'background-image':
            'url(' + data.overviewImageModel.url + ')',
    }"></div>
                <div v-else-if="!data.overviewImageUrl && mediaDetailsObj" class="background-wrapper" v-bind:style="{
        'background-image':
            'url(' + getVixyThumbnailUrl() + ')',
    }"></div>
            </div>

            <div class="video-meta regular" v-if="mediaDetailsObj">
                <ul class="labels" v-if="data.fields">
                    <li v-if="data.fields.skills && data.fields.skills.length > 0
        ">
                        <div class="item-wrapper" v-for="item in data.fields.skills" :key="item.value">
                            <CatAsIcon :catValue="getEnumVal(item.value)" type="skill" />

                            <span class="label-wrapper">
                                {{ getEnumVal(item.value) }}
                            </span>
                        </div>
                    </li>

                    <li v-if="data.fields.videofilter4 &&
        data.fields.videofilter4.length > 0
        ">
                        <div class="item-wrapper" v-for="item in data.fields.videofilter4" :key="item.value">
                            <CatAsIcon :catValue="getEnumVal(item.value)" type="skill" />

                            <span class="label-wrapper">
                                {{ getEnumVal(item.value) }}
                            </span>
                        </div>
                    </li>

                    <li v-if="data.fields.section &&
        data.fields.section.length > 0
        ">
                        <div class="item-wrapper" v-for="item in data.fields.section" :key="item.value">
                            <CatAsIcon :catValue="getEnumVal(item.value)" type="level" />

                            <span class="label-wrapper">
                                {{ getEnumVal(item.value) }}
                            </span>
                        </div>
                    </li>

                    <li v-if="data.fields.level && data.fields.level.length > 0">
                        <div class="item-wrapper" v-for="item in data.fields.level" :key="item.value">
                            <CatAsIcon :catValue="getEnumVal(item.value)" type="level" />

                            <span class="label-wrapper">
                                {{ getEnumVal(item.value) }}
                            </span>
                        </div>
                    </li>

                    <li v-if="data.freeVideo">
                        <div class="item-wrapper looks-as-label free">Free</div>
                    </li>
                    <li class="categories" v-if="data.fields &&
        data.fields.Categories &&
        data.fields.Categories.length > 0
        ">
                        <div class="item-wrapper looks-as-label" v-for="item in data.fields.Categories"
                            :key="item.value">
                            <span class="label-wrapper">
                                {{ getEnumVal(item.value) }}
                            </span>
                        </div>
                    </li>
                </ul>

                <h3 class="video-title" v-if="data.title">
                    {{ reformatDesktopTitle(data.title) }}
                </h3>

                <div class="trainer-wrapper" v-if="data.fields &&
        data.fields.coachestrainers &&
        data.fields.coachestrainers.length > 0
        ">
                    <div class="item-wrapper" v-for="item in data.fields.coachestrainers" :key="item.value">
                        <span class="label-wrapper">
                            {{ getEnumVal(item.value) }}
                        </span>
                    </div>
                </div>
                <div class="duration" v-if="mediaDetailsObj.msDuration &&
        mediaDetailsObj.msDuration !== ''
        ">
                    <img src="@/assets/svgs/duration.svg" class="duration-icon" />
                    <span>{{
        formatToClockDuration(mediaDetailsObj.msDuration)
                        }}</span>
                </div>
            </div>

            <div class="item-state"></div>
        </div>
    </button>
</template>

<script>
//import { fetchMediaDetails } from "@streampac.io/chef_sp_1";

import {
    fetchVideoAsset,
    fetchTemplateContentList,
} from "@streampac.io/chef_sp_1";

import CatAsIcon from "@/components/CatAsIcon";

export default {
    inject: ["isAuthenticated"],
    props: {
        observer: null,
        doCalcOverlayPosition: {
            type: Boolean,
            default: () => { },
        },
        data: {
            type: Object,
            default: () => { },
        },
    },
    components: {
        CatAsIcon,
    },
    //emits: ["itemId"],
    data() {
        return {
            activeClass: false,
            isLoading: true,
            mediaDetailsObj: null,
            mainVideoType: "",
            trainerData: null,
            linkedTrainer: null,
            displayNewLabel: false,
            itemClass: "video-item",
        };
    },
    mounted() {
        if (this.observer !== null && this.observer !== undefined) {
            this.observer.observe(this.$el);
        }

        this.fetchDetails();

        if (
            this.data &&
            this.DoesObjHasEnum(this.data.fields, "Categories", "New")
        ) {
            this.displayNewLabel = true;
        }

        if (
            this.data &&
            this.data.fields &&
            this.data.fields.coachestrainers &&
            this.data.fields.coachestrainers.length > 0
        ) {
            this.linkedTrainer = this.data.fields.coachestrainers[0].value;
        }

        if (
            this.data &&
            this.data.fields &&
            this.data.fields.videotype &&
            this.data.fields.videotype.length > 0
        ) {
            this.mainVideoType = this.getEnumVal(
                this.data.fields.videotype[0].value
            );

            if (this.mainVideoType && this.mainVideoType == "Masterclass") {
                const fetchDataParams = {
                    filter: {
                        searchTerm: this.linkedTrainer,
                    },
                    paging: {
                        pageNumber: 1,
                        pageSize: 10,
                    },
                    flattenFields: true,
                };

                const getContentTypes = this.$store.state.getContentTypes;

                if (getContentTypes) {
                    const chosenType = getContentTypes.trainersAndCoaches;

                    fetchTemplateContentList(
                        this.isAuthenticated,
                        chosenType,
                        fetchDataParams,
                        "?flattenFields=true"
                    ).then((response) => {
                        this.trainerData = response[0];
                    });
                }

                this.itemClass = "masterclass-video-item";
            }
        }
    },
    methods: {
        fetchDetails() {
            //Fetch video asset data
            fetchVideoAsset(this.isAuthenticated, this.data.mediaId).then(
                (response) => {
                    this.mediaDetailsObj = response;

                    //some extra time to display item. This way it has all the content loaded before displaying.
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                }
            );
        },
        reformatDesktopTitle(title) {
            let adjustedTitle = title;

            // if (title.length > 22) {
            //     adjustedTitle = title.substring(0, 22) + "..";
            // }

            return adjustedTitle;
        },
        reformatMobileTitle(title) {
            let adjustedTitle = title;

            if (title.length > 38) {
                adjustedTitle = title.substring(0, 34) + "..";
            }

            return adjustedTitle;
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, 0);
        },
        formatToClockDuration(milliseconds) {
            let seconds = Math.floor(milliseconds / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);

            seconds = seconds % 60;

            minutes = seconds >= 30 ? minutes + 1 : minutes;

            minutes = minutes % 60;

            hours = hours % 24;

            if (hours > 0) {
                return `${this.padTo2Digits(hours)}:${this.padTo2Digits(
                    minutes
                )}:${this.padTo2Digits(seconds)}`;
            } else {
                return `${this.padTo2Digits(minutes)}:${this.padTo2Digits(
                    seconds
                )}`;
            }
        },
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    return enumFilter[0].enumValue;
                }
            }

            return "";
        },
        getVixyThumbnailUrl() {
            if (this.mediaDetailsObj && this.mediaDetailsObj !== "") {
                const originalStr = this.mediaDetailsObj.thumbnailUrl;

                //const newStr = originalStr.replace("/width/200", "/width/600");
                const newStr = originalStr.replace(
                    "/width/200",
                    "/width/640/height/360/type/4/"
                );

                return newStr;
            }
        },
        DoesObjHasEnum(fieldsObj, definedEnum, checkForEnumName) {
            if (
                fieldsObj &&
                fieldsObj !== "" &&
                fieldsObj[definedEnum] &&
                fieldsObj[definedEnum].length > 0
            ) {
                let enumNames = [];

                if (
                    fieldsObj[definedEnum] &&
                    fieldsObj[definedEnum].length > 0
                ) {
                    fieldsObj[definedEnum].forEach((singleEnum) => {
                        const enumAsName = this.getEnumVal(singleEnum.value);

                        enumNames.push(enumAsName);
                    });

                    if (enumNames.includes(checkForEnumName)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        toggleActiveState() {
            this.activeClass = !this.activeClass;

            if (this.data?.id) {
                // this.$emit("itemId", this.data.id);

                //this.$store.commit("setTrainingStageElements", this.data.id);

                if (!this.$store.state.getTrainingStageElements.includes(this.data.id)) {
                    //this.$store.state.getTrainingStageElements.push(this.data.id);

                    let currentItems = this.$store.state.getTrainingStageElements;

                    currentItems.push(this.data.id);

                    this.$store.commit("setTrainingStageElements", currentItems);

                    // console.log('add');
                } else {
                    const index = this.$store.state.getTrainingStageElements.indexOf(this.data.id);

                    let currentItems = this.$store.state.getTrainingStageElements;

                    currentItems.splice(index, 1);

                    this.$store.commit("setTrainingStageElements", currentItems);

                    // console.log('remove');

                }

                //console.log(this.$store.state.getTrainingStageElements);
            }
        },
        // getAssetById(){
        //     if( this.data && this.data.overviewImage && this.data.overviewImage !== '' ){

        //     }
        // }
        // returnCatImg(val) {
        //     if (val && val !== '') {
        //         if (val == 'passing') {
        //             return passingIcon;
        //         } else if (val == 'defending') {
        //             return defendingIcon;
        //         } else if (val == 'scoring') {
        //             return scoringIcon;
        //         } else if (val == 'receiving') {
        //             return receivingIcon;
        //         } else {
        //             return categoryIcon;
        //         }
        //     } else {
        //         return categoryIcon;
        //     }

        // },
        // returnLevelImage(val) {
        //     if (val && val !== '') {
        //         if (val == 'basic') {
        //             return basicIcon;
        //         } else if (val == 'advanced') {
        //             return advancedIcon;
        //         } else if (val == 'pro') {
        //             return proIcon;
        //         } else {
        //             return proIcon;
        //         }
        //     } else {
        //         return proIcon;
        //     }

        // }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
