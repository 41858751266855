<template>
    <div class="top-banner">
        <h2 class="search-filter-title">Add video to journey</h2>
        <div class="search-filter-type-wrapper" :class="activeGridSearchFilter">
            <div class="big-filter-wrapper" v-if="activeGridSearchFilter === 'filter'">
                <div class="main-filter-wrapper dropdown" v-if="collectionFilter">
                    <!-- <button @click="showMobileFilterFunction()" class="show-filter-btn"
                        :class="{ 'show-mobile-filter': showMobileFilter }">
                        Filter
                    </button> -->
                    <!-- <div class="inner-wrapper" :class="{ 'show-mobile-filter': showMobileFilter }"> -->
                    <!-- <div class="inner-wrapper"> -->
                    <!-- <button v-if="displayResetBtn" class="reset-all-filters" @click="resetAllFilters()">Reset all</button> -->
                    <div class="inner-wrapper">
                        <div class="filter-wrapper" v-for="filterType in catFilters" :key="filterType.name">
                            <!-- <button class="overlay"></button> -->
                            <select @change="onFilterChange" v-model="filterVal[filterType.name]">
                                <option selected="selected" value="*">
                                    {{ getCorrectFilterLabel(filterType.name) }}
                                </option>
                                <option v-for="child in filterType.children" :key="child.id" :value="child.id">
                                    {{ child.enumValue }}
                                </option>
                            </select>

                        </div>

                    </div>
                    <!-- </div> -->
                </div>
            </div>
            <div class="big-search-wrapper" v-if="activeGridSearchFilter === 'search'">
                <form class="search-form" @submit.prevent="doSearch" autocomplete="off">
                    <input type="text" v-model="userInput" class="search-bar" placeholder="Search" ref="searchBar" />
                    <button class="extra-btn" type="button" @click="doSearch()"></button>
                </form>
            </div>
        </div>
        <div class="search-filter-toggle">
            <ul>
                <li>
                    <button class="button" @click="changeSearchFilterMethod('search')" :class="{
            active: activeGridSearchFilter === 'search',
        }">
                        Search
                    </button>
                </li>
                <li>
                    <button class="button" @click="changeSearchFilterMethod('filter')" :class="{
            active: activeGridSearchFilter === 'filter',
        }">
                        Filter
                    </button>
                </li>
            </ul>
        </div>

    </div>
    <div class="item-list-container">
        <div class="inner-list-wrapper">
            <transition name="slow-fade">
                <div class="items-wrapper" v-if="videoContentArray && videoContentArray.length > 0">
                    <VideoOverviewToggleItem v-for="item in videoContentArray" :key="item.mediaId" :data="item"
                        :observer="observer" />
                </div>
            </transition>
            <transition name="slow-fade">
                <div class="message no-items-found" v-if="showNoItemsFound && !showLazyLoadSpinner">
                    Geen video's meer gevonden..
                </div>
            </transition>
            <transition name="fade">
                <div class="lazyload-spinner" v-if="showLazyLoadSpinner">
                    <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
    <div class="bottom-banner">
        <button class="button" @click="submitSelectionToTraining">
            Add to journey
        </button>
    </div>
</template>

<script>
// @import component
import VideoOverviewToggleItem from "@/components/VideoOverviewToggleItem";

import {
    fetchNestedEnumValues,
    fetchVideoContentList,
} from "@streampac.io/chef_sp_1";

// import Flickity from "@/components/Flickity";


// import { fetchMultipleVideoAssets } from "@/dist";

export default {
    name: "TrainingGridCollection",
    components: {
        VideoOverviewToggleItem,
        // Flickity
    },
    inject: ["isAuthenticated"],
    data() {
        return {
            activeGridSearchFilter: "search",
            disableClick: true,
            showNoItemsFound: false,
            loadedAllItemFound: false,
            observer: null,
            totalItems: 0,
            totalLoadedItems: 0,
            catFilters: [],
            filterVal: [],
            showLazyLoadSpinner: false,
            data: null,
            videoContentArray: [],
            showMobileFilter: false,
            userInput: null,
            fetchDataParams: {
                filter: {
                    searchTerm: "",
                },
                paging: {
                    pageNumber: 1,
                    pageSize: 1,
                },
                // orderBy: {
                // 	orderByField: "PublicationDate",
                // 	orderDesc: false,
                // },
                flattenFields: true,
            },
            // flickityOptions: {
            //     initialIndex: 0,
            //     cellAlign: "left",
            //     contain: true,
            //     pageDots: false,
            //     draggable: true,
            //     groupCells: true,
            //     prevNextButtons: false,
            //     watchCSS: true,
            // },
        };
    },
    props: {
        collectionFilter: {
            type: Object,
            required: true,
        },
        paging: {
            type: Object,
            required: true,
        },
        searchParams: {
            type: Object,
            required: false,
        },
        enumId: {
            type: String,
            required: false,
        },
        onlyShowFree: {
            type: Boolean,
            required: false,
        },
    },
    created() {
        this.observer = new IntersectionObserver(this.onElementObserved, {
            root: this.$el,
            threshold: 0.5,
        });

        if (this.paging) {
            this.fetchDataParams.paging.pageNumber = this.paging.pageNumber;
            this.fetchDataParams.paging.pageSize = this.paging.pageSize;
        }

        if (this.searchParams && this.searchParams !== "") {
            this.searchParams.forEach((item) => {
                this.filterVal[item.type] = item.value;
            });
        }

        if (this.collectionFilter) {
            this.collectionFilter.forEach((filterType) => {
                this.filterVal[filterType] = "*";
            });

            setTimeout(() => {
                fetchNestedEnumValues(this.isAuthenticated).then((response) => {
                    if (response && response.length > 0) {
                        response.forEach((name) => {
                            this.collectionFilter.forEach((filterType) => {
                                if (name.enumName === filterType) {
                                    let enumObj = {
                                        name: name.enumName,
                                        children: name.enumerationValues,
                                    };

                                    this.catFilters.push(enumObj);
                                }
                            });
                        });

                        //Sort order by order collectionFilter order.
                        this.catFilters.sort((a, b) => {
                            return (
                                this.collectionFilter.indexOf(a.name) -
                                this.collectionFilter.indexOf(b.name)
                            );
                        });
                    }
                });
            }, 100);
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (queryString && queryString !== "") {
            for (const [key, value] of urlParams.entries()) {
                if (value && value !== "") {
                    this.filterVal[key] = value;
                }
            }
        }

        setTimeout(() => {
            //this.fetchVideoContentData();
            this.changeFetchParams(this.filterVal);
        }, 100);

        if (this.onlyShowFree && this.onlyShowFree === true) {
            this.fetchDataParams.filter.free = true;
        }

        //On load check if the reset button needs display
        this.checkDisplayResetFilterBtn();
    },
    mounted() {

    },
    // watch: {
    //     activeGridSearchFilter() {
    //         if (this.activeGridSearchFilter == 'filter') {
    //             this.enableFlickityStuff();
    //         }
    //     },
    // },
    unmounted() {
        // this.observer.unobserve(this.$el);
    },
    // emits: ["itemId"],
    methods: {
        // enableFlickityStuff() {
        //     setTimeout(() => {

        //         //let isDragging = true;
        //         //var initialX, initialY;

        //         //console.log(this.$refs.flickity.$flickity);
        //         this.$refs.flickity.on("dragStart", () => {
        //             console.log('drag start');
        //             // this.disableClick = true;
        //             // this.$refs.flickity[0].$flickity.element.style.pointerEvents = "none";
        //             // console.log(this.$refs.flickity[0].$flickity.element);

        //             //isDragging = true;
        //         });
        //         this.$refs.flickity.$flickity.on("dragEnd", () => {
        //             console.log('drag end');
        //             // this.disableClick = true;
        //             //this.$refs.flickity[0].$flickity.element.style.pointerEvents = "auto";
        //             //isDragging = false;
        //         });

        //         // this.$refs.flickity.$flickity.on("pointerUp", (event) => {
        //         //     if (event.target.tagName === 'BUTTON') {
        //         //         console.log('Button was clicked!');

        //         //         setTimeout(() => {
        //         //             // Get the next sibling element
        //         //             var nextElement = event.target.nextElementSibling;

        //         //             // Ensure the next element exists and is a select element
        //         //             if (nextElement && nextElement.tagName === 'SELECT') {
        //         //                 nextElement.focus();  // Focus on the select element to simulate interaction
        //         //                 console.log('Select element was focused');
        //         //             } else {
        //         //                 console.log('No next sibling select element found.');
        //         //             }
        //         //         }, 50);
        //         //     }
        //         // });

        //         // this.$refs.flickity.$flickity.on("pointerDown", () => {
        //         //     console.log('dpointer down');
        //         //     this.disableClick = false;
        //         //     //this.$refs.flickity[0].$flickity.element.style.pointerEvents = "auto";
        //         // });

        //         // document.querySelectorAll('#flickity-options-wrapper button').forEach(function (btn) {
        //         //     //console.log(btn);
        //         //     btn.addEventListener('pointerup', function () {
        //         //         //e.preventDefault();
        //         //         console.log('Mouseup');
        //         //         // event.preventDefault();
        //         //         // if (isDragging) {
        //         //         //     // If the user was dragging, prevent the select from opening
        //         //         //     event.preventDefault();
        //         //         // }
        //         //         // Reset isDragging after mouse is released
        //         //         //isDragging = false;
        //         //     });
        //         // });

        //         // document.querySelectorAll('#flickity-options-wrapper select').forEach(function (select) {

        //         //     // select.addEventListener('mousedown', function (event) {
        //         //     //     console.log('mouseDown');
        //         //     //     // Record the initial mouse position
        //         //     //     initialX = event.clientX;
        //         //     //     initialY = event.clientY;
        //         //     //     isDragging = false;
        //         //     // });

        //         //     // select.addEventListener('mousemove', function (event) {
        //         //     //     console.log('mouseMove');
        //         //     //     // Calculate the distance moved
        //         //     //     var deltaX = Math.abs(event.clientX - initialX);
        //         //     //     var deltaY = Math.abs(event.clientY - initialY);
        //         //     //     // If the movement is significant, consider it a drag
        //         //     //     if (deltaX > 5 || deltaY > 5) {
        //         //     //         isDragging = true;
        //         //     //     }
        //         //     // });

        //         //     select.addEventListener('mouseup', function (event) {
        //         //         console.log('Mouseup');
        //         //         event.preventDefault();
        //         //         if (isDragging) {
        //         //             // If the user was dragging, prevent the select from opening
        //         //             event.preventDefault();
        //         //         }
        //         //         // Reset isDragging after mouse is released
        //         //         //isDragging = false;
        //         //     });

        //         //     // select.addEventListener('click', function (event) {
        //         //     //     console.log('click');
        //         //     //     if (isDragging) {
        //         //     //         // Prevent click event if dragging
        //         //     //         event.preventDefault();
        //         //     //     }
        //         //     // });

        //         // }, 100);

        //     }, 100);
        // },
        onElementObserved(entries) {
            entries.forEach(({ target, isIntersecting }) => {
                if (isIntersecting) {
                    this.observer.unobserve(target);
                    this.totalItems = this.totalItems + 1;
                    this.totalLoadedItems = this.totalLoadedItems + 1;
                }
            });

            if (this.totalItems == this.fetchDataParams.paging.pageSize) {
                setTimeout(() => {
                    this.fetchDataParams.paging.pageNumber =
                        this.fetchDataParams.paging.pageNumber + 1;

                    this.fetchVideoContentData(
                        this.fetchDataParams.paging.pageNumber
                    );
                }, 50);

                //reset count to 0 again
                this.totalItems = 0;
            }
        },
        fetchVideoContentData(val) {
            this.showLazyLoadSpinner = true;
            this.fetchDataParams.paging.pageNumber = val;

            if (val == undefined) this.fetchDataParams.paging.pageNumber = 1;

            let inputData = this.fetchDataParams;

            fetchVideoContentList(this.isAuthenticated, inputData, true).then(
                (response) => {
                    //this.showLazyLoadSpinner = true;
                    this.data = response.data;

                    const paginationHeaders = JSON.parse(
                        response.headers["x-pagination"]
                    );

                    this.totalCount = paginationHeaders.TotalCount;

                    if (paginationHeaders.TotalCount == 0) {
                        this.showNoItemsFound = true;
                    }

                    if (paginationHeaders.TotalCount == this.totalLoadedItems) {
                        this.loadedAllItemFound = true;
                    }

                    this.processFetch();

                    this.showLazyLoadSpinner = false;
                }
            );
        },
        processFetch() {
            let videoContentArray = this.videoContentArray;

            if (this.data) {
                this.data.forEach((item) => {
                    videoContentArray.push(item);
                });
            }
        },
        changeFetchParams(val) {
            //reset data to prepare for filtering
            this.data = null;
            this.videoContentArray = [];
            this.fetchDataParams.paging.pageNumber = 1;
            this.loadedAllItemFound = false;
            this.totalLoadedItems = 0;
            this.showLazyLoadSpinner = false;
            this.showNoItemsFound = false;

            const valValuesArray = Object.values(val);

            var filteredArray = valValuesArray.filter(function (e) {
                return e !== "*";
            });

            const stringifyVal = filteredArray.toString().replaceAll(",", "; ");

            //Correctly set searchTerm here
            this.fetchDataParams.filter.searchTerm = stringifyVal;

            //this.changeUrlParameters();

            //Fetch the content
            this.fetchVideoContentData(1);
        },
        changeSearchFilterMethod(type) {

            //Reset filters
            this.resetAllFilters();
            this.resetSearch();

            if (type == "search") {
                this.activeGridSearchFilter = "search";
            } else {
                this.activeGridSearchFilter = "filter";
            }
        },
        resetSearch() {
            this.userInput = null;
        },
        submitSelectionToTraining() {
            this.$store.commit(
                "triggerSubmitTrainingStageElementsToTraining",
                true
            );

            setTimeout(() => {
                this.$store.commit(
                    "triggerSubmitTrainingStageElementsToTraining",
                    false
                );
            }, 100);
        },
        showMobileFilterFunction() {
            this.showMobileFilter = !this.showMobileFilter;
        },
        getCorrectFilterLabel(val) {
            if (val == "Exercises") {
                return "All exercises";
            } if (val == "Categories") {
                return "All categories";
            } else if (val == "Level") {
                return "All levels";
            } else if (val == "Skills") {
                return "All skills";
            } else if (val == "Main Categories") {
                return "All Categories";
            } else if (val == "Classes") {
                return "All classes";
            } else {
                return val;
            }
        },
        onFilterChange() {
            this.changeFetchParams(this.filterVal);
            this.totalItems = 0;
            this.totalLoadedItems = 0;

            this.checkDisplayResetFilterBtn();
        },
        resetAllFilters() {
            if (this.filterVal && this.filterVal !== "") {
                Object.keys(this.filterVal).forEach((key) => {
                    if (
                        this.collectionFilter &&
                        this.collectionFilter !== "" &&
                        this.collectionFilter.includes(key)
                    ) {
                        this.filterVal[key] = "*";
                    }
                });

                this.onFilterChange();
            }
        },
        checkDisplayResetFilterBtn() {
            let valsArray = [];

            //Go through each filter val
            Object.keys(this.filterVal).forEach((key) => {
                if (
                    this.collectionFilter &&
                    this.collectionFilter !== "" &&
                    this.collectionFilter.includes(key)
                ) {
                    if (this.filterVal[key] !== "*") {
                        //if val equals a real value
                        valsArray.push(this.filterVal[key]);
                    }
                }
            });

            if (valsArray && valsArray.length > 0) {
                this.displayResetBtn = true;
            } else {
                this.displayResetBtn = false;
            }
        },
        doSearch() {
            this.fetchDataParams.filter.searchTerm = this.userInput;

            this.totalItems = 0;
            this.totalLoadedItems = 0;

            //reset + activate search
            this.data = null;
            this.videoContentArray = [];
            this.fetchDataParams.paging.pageNumber = 1;
            this.loadedAllItemFound = false;
            this.totalLoadedItems = 0;
            this.showLazyLoadSpinner = false;
            this.showNoItemsFound = false;

            //Fetch the content
            this.fetchVideoContentData(1);

        }
        // mutateItemList(itemId) {
        //     // if (itemId) {
        //     //     this.$emit("itemId", itemId);
        //     // }
        // }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
// @import styles
@import "./index-no-scope.scss";
</style>
